// eslint-disable-next-line node/no-extraneous-import
import singletonRouter from "next/router";

// necessary to patch odd NextJS bug on back button where route doesn't update
let hasRun = false;
function initSingleton(): void {
    if (!hasRun) {
        hasRun = true;
        window.addEventListener("popstate", (e) => {
            const windowPath = `${window.location.pathname || ""}${window.location.search || ""}${
                window.location.hash || ""
            }`;
            if (singletonRouter.asPath !== windowPath) {
                nextRouterReplace(windowPath.replace(singletonRouter.basePath, ""));
            }
        });
    }
}

type PushParams = Parameters<typeof singletonRouter.push>;
export function nextRouterPush(
    url: PushParams[0],
    as?: PushParams[1],
    options?: PushParams[2],
): Promise<boolean> {
    initSingleton();
    return singletonRouter.push(url, as, options);
}

type ReplaceParams = Parameters<typeof singletonRouter.replace>;
export function nextRouterReplace(
    url: ReplaceParams[0],
    as?: ReplaceParams[1],
    options?: ReplaceParams[2],
): Promise<boolean> {
    initSingleton();
    return singletonRouter.replace(url, as, options);
}
