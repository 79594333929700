import React from "react";
import { CustomerPassTypedRoute } from "@customer-pass/definitions/routeDefinitions";
import { LoginPageCmp } from "@customer-pass/components/LoginPageCmp";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { defaultEndpoint, defaultScriptUrlPattern, FpjsProvider } from "@fingerprintjs/fingerprintjs-pro-react";
const LoginPage: CustomerPassTypedRoute<"/login"> = props => <FpjsProvider loadOptions={{
  apiKey: "bRWsfMpLgEu4jGFP0aq1",
  endpoint: ["https://novel.com/YfMjRv52UKYTrn9E/Ki08S5EqgYSjopzU", defaultEndpoint],
  scriptUrlPattern: ["https://novel.com/YfMjRv52UKYTrn9E/sngDJl1mijcAxd6V?apiKey=<apiKey>&version=<version>&loaderVersion=<loaderVersion>", defaultScriptUrlPattern]
}}>
        <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID!}>
            <LoginPageCmp {...props} />
        </GoogleOAuthProvider>
    </FpjsProvider>;
LoginPage.isPublicRoute = true;
LoginPage.isLoggedOutRoute = true;
LoginPage.uselessNextJsRedeploy = true;
export default LoginPage;