import React, { useCallback, useEffect } from "react";
import cx from "classnames";
import useDigitInput from "./UseDigitInput";
import { FixedPxBox } from "@novel/shared/components/Box";
import { useIsMobile } from "@novel/shared/hooks/useIsMobile";
interface SixDigitCodeComponentProps {
  readonly className?: string;
  readonly style?: React.CSSProperties;
  readonly value: string;
  readonly onChange: (newValue: string) => void;
}
const digitRegex = /^[0-9]$/;
export function SixDigitCodeComponent({
  className,
  style,
  value,
  onChange
}: SixDigitCodeComponentProps): JSX.Element {
  const isMobile = useIsMobile();
  useEffect(() => {
    const firstele: any = document!.getElementById("firstDigit");
    firstele.disabled = false;
    firstele!.focus();
  }, []);
  const digitInputOnChange = useCallback((newValue: string) => {
    onChange(newValue);
  }, [onChange]);
  const digits = useDigitInput({
    acceptedCharacters: digitRegex,
    length: 6,
    value,
    onChange: digitInputOnChange
  });
  return <FixedPxBox columnGap={isMobile ? 1 : 2} className={cx("six-digit-code-container", className)} style={style}>
            <input inputMode="decimal" id="firstDigit" className="input-digit" autoComplete="new-password" placeholder="-" autoFocus {...digits[0]} />
            <input className="input-digit" autoComplete="new-password" placeholder="-" inputMode="decimal" {...digits[1]} />
            <input className="input-digit" autoComplete="new-password" placeholder="-" inputMode="decimal" {...digits[2]} />
            <input className="input-digit" autoComplete="new-password" placeholder="-" inputMode="decimal" {...digits[3]} />
            <input className="input-digit" autoComplete="new-password" placeholder="-" inputMode="decimal" {...digits[4]} />
            <input className="input-digit" autoComplete="new-password" placeholder="-" inputMode="decimal" id="lastDigit" {...digits[5]} />
        </FixedPxBox>;
}