import { GetServerSidePropsContext, GetServerSidePropsResult, NextPage } from "next";
import { stringify as qsStringify } from "qs";
import { nextRouterPush, nextRouterReplace } from "@novel/shared/nextRouteOps";
import { NOVEL_PASS_USER_EMAIL_PARAM } from "@novel/shared/utils/appConstants";
import {
    NOVEL_PASS_LINK_TYPE_PARAM,
    NOVEL_PASS_CAMPAIGN_ID_PARAM,
} from "@novel/shared/utils/appStorefrontConstants";

interface CustomerPassRoutesMap {
    "/": {
        serverProps: {};
        routeParams: {};
        queryParams: {
            // "email" will be provided but that is for POS consumption, not app consumption
            referralCode?: string;
            [NOVEL_PASS_LINK_TYPE_PARAM]?: string;
        };
    };
    "/:sourceTag": {
        serverProps: {};
        routeParams: {};
        queryParams: {
            // "email" will be provided but that is for POS consumption, not app consumption
            referralCode?: string;
            [NOVEL_PASS_LINK_TYPE_PARAM]?: string;
        };
    };
    "/auth": {
        serverProps: {};
        routeParams: {};
        queryParams: {
            redirectUrl?: string;
            [NOVEL_PASS_CAMPAIGN_ID_PARAM]?: string;
            novelPass?: string;
            [NOVEL_PASS_USER_EMAIL_PARAM]?: string;
            [NOVEL_PASS_LINK_TYPE_PARAM]: string;
            foreign?: string;
        };
    };
    "/share": {
        serverProps: {};
        routeParams: {};
        queryParams: {
            redirectUrl: string;
            [NOVEL_PASS_USER_EMAIL_PARAM]?: string;
            message?: string;
        };
    };
    "/oauth/google": {
        serverProps: {};
        routeParams: {};
        queryParams: {
            [NOVEL_PASS_USER_EMAIL_PARAM]?: string;
            originalUrl: string;
        };
    };
    "/login": {
        serverProps: {};
        routeParams: {};
        queryParams: {
            [NOVEL_PASS_USER_EMAIL_PARAM]?: string;
            nocode?: string;
            code?: string;
            redirectAppUrl?: string;
            referralCode?: string;
            redirectUrl?: string;
            [NOVEL_PASS_CAMPAIGN_ID_PARAM]?: string;
            novelPass?: string;
            multiPassBoolean?: string;
            [NOVEL_PASS_LINK_TYPE_PARAM]?: string;
            sourceTag?: string;
        };
    };
    // intentionally no page for this route, will be 404
    "/not-found": {
        serverProps: {};
        routeParams: {};
        queryParams: {
            referralCode?: string;
        };
    };
}

export type CustomerPassRoute = keyof CustomerPassRoutesMap;

export type CustomerPassTypedRoute<SpecificRoute extends CustomerPassRoute> = NextPage<{
    // wrapping in "props" to avoid conflict with native React props (such as "ref")
    props: CustomerPassRoutesMap[SpecificRoute]["routeParams"] &
        CustomerPassRoutesMap[SpecificRoute]["queryParams"] &
        CustomerPassRoutesMap[SpecificRoute]["serverProps"];
}> & {
    isPublicRoute?: boolean;
    isLoggedOutRoute?: boolean;
    isSuperUserRoute?: boolean;
    uselessNextJsRedeploy?: boolean;
};

export type CustomerPassTypedGetServerSideProps<SpecificRoute extends CustomerPassRoute> = (
    context: GetServerSidePropsContext<CustomerPassRoutesMap[SpecificRoute]["routeParams"]> & {
        params: CustomerPassRoutesMap[SpecificRoute];
    },
) => Promise<GetServerSidePropsResult<CustomerPassRoutesMap[SpecificRoute]["serverProps"]>>;

export function customerPassRoutePush<R extends CustomerPassRoute>(
    customerRoute: R,
    routeParams: CustomerPassRoutesMap[R]["routeParams"] = {},
    queryParams: CustomerPassRoutesMap[R]["queryParams"] = {},
    isShallow = true,
): Promise<boolean> {
    const nextRoute = mapParamsObjectToRoute(customerRoute, routeParams, queryParams);

    // shallow skips any API calls, making it a client-side only route change
    return nextRouterPush(
        nextRoute,
        nextRoute,
        !isShallow
            ? {}
            : {
                  shallow: true,
              },
    );
}

export function customerPassRouteReplace<R extends CustomerPassRoute>(
    customerRoute: R,
    routeParams: CustomerPassRoutesMap[R]["routeParams"] = {},
    queryParams: CustomerPassRoutesMap[R]["queryParams"] = {},
    isShallow = true,
): Promise<boolean> {
    const nextRoute = mapParamsObjectToRoute(customerRoute, routeParams, queryParams);

    // shallow skips any API calls, making it a client-side only route change
    return nextRouterReplace(
        nextRoute,
        nextRoute,
        !isShallow
            ? {}
            : {
                  shallow: true,
              },
    );
}

function mapParamsObjectToRoute<R extends CustomerPassRoute>(
    customerRoute: R,
    routeParams: CustomerPassRoutesMap[R]["routeParams"] = {},
    queryParams: CustomerPassRoutesMap[R]["queryParams"] = {},
) {
    const route = Object.keys(routeParams).reduce((accum: string, paramName: string) => {
        const castedParamName = paramName as keyof CustomerPassRoutesMap[R]["routeParams"];
        const paramValue = routeParams[castedParamName] as unknown as string; // unsure why this is necessary
        return accum.replace(`:${paramName}`, encodeURIComponent(paramValue));
    }, customerRoute);

    return `${route}${qsStringify(queryParams, { addQueryPrefix: true })}`;
}
