import { NovelCustomerPassThunkAction } from "@customer-pass/redux/customerPassTypedRedux";
import { asyncDispatch } from "@customer-pass/redux/utils/asyncDispatch";
import { loadingToast } from "@novel/shared/utils/toastUtils";

import { typedFrontendPassApi } from "@customer-pass/utils/typedFrontendPassApi";
import {
    NOVEL_PASS_CAMPAIGN_ID_PARAM,
    NOVEL_PASS_LINK_TYPE_PARAM,
} from "@novel/shared/utils/appStorefrontConstants";

interface AddEmailForAccount {
    email: string;
    sessionToken: string;
}

export const addEmailForAccount =
    ({
        email,
        sessionToken,
    }: AddEmailForAccount): NovelCustomerPassThunkAction<
        "ADDING_EMAIL_FOR_ACCOUNT" | "ERROR_ADDING_EMAIL_FOR_ACCOUNT" | "ADDED_EMAIL_FOR_ACCOUNT"
    > =>
    async (dispatch) => {
        const { onSuccessToast, onErrorToast } = loadingToast("Adding email to account...");
        return asyncDispatch(
            typedFrontendPassApi.postReq("/api/customer/auth/passwordless/email/add", {
                reqBody: {
                    email,
                    sessionToken,
                },
            }),
            () =>
                dispatch({
                    type: "ADDING_EMAIL_FOR_ACCOUNT",
                    payload: undefined,
                }),
            (failure) => {
                onErrorToast(failure.error.message);
                return dispatch({
                    type: "ERROR_ADDING_EMAIL_FOR_ACCOUNT",
                    payload: {
                        errorMessage: failure.error.message,
                    },
                });
            },
            () => {
                onSuccessToast("Email added to account!");
                return dispatch({
                    type: "ADDED_EMAIL_FOR_ACCOUNT",
                    payload: {},
                });
            },
        );
    };

// would send email with just the link (no 6-digit code)
export const requestLoginTokenEmail =
    ({
        email,
        referralCode,
        redirectUrl,
        campaignId,
        sourceTag,
        passLinkType,
    }: {
        email: string;
        referralCode?: string;
        redirectUrl?: string;
        sourceTag?: string;
        [NOVEL_PASS_CAMPAIGN_ID_PARAM]?: string;
        [NOVEL_PASS_LINK_TYPE_PARAM]?: string;
    }): NovelCustomerPassThunkAction<
        "REQUESTING_TOKEN" | "ERROR_REQUESTING_TOKEN" | "REQUESTED_TOKEN"
    > =>
    async (dispatch) => {
        const { onSuccessToast, onErrorToast } = loadingToast("Sending verification email...");

        return asyncDispatch(
            typedFrontendPassApi.postReq("/api/customer/auth/passwordless/email", {
                reqBody: {
                    email,
                    referralCode,
                    sourceTag,
                    redirectUrl,
                    [NOVEL_PASS_CAMPAIGN_ID_PARAM]: campaignId,
                    [NOVEL_PASS_LINK_TYPE_PARAM]: passLinkType,
                },
            }),
            () =>
                dispatch({
                    type: "REQUESTING_TOKEN",
                    payload: undefined,
                }),
            (failure) => {
                onErrorToast(failure.error.message);
                return dispatch({
                    type: "ERROR_REQUESTING_TOKEN",
                    payload: {
                        errorMessage: failure.error.message,
                    },
                });
            },
            () => {
                onSuccessToast("Verification email sent!");
                return dispatch({
                    type: "REQUESTED_TOKEN",
                    payload: {},
                });
            },
        );
    };

export const requestLoginTokenCodeEmail =
    ({
        email,
    }: {
        email: string;
    }): NovelCustomerPassThunkAction<
        "REQUESTING_TOKEN_CODE" | "ERROR_REQUESTING_TOKEN_CODE" | "REQUESTED_TOKEN_CODE"
    > =>
    async (dispatch) => {
        const { onSuccessToast, onErrorToast } = loadingToast("Sending verification email...");

        return asyncDispatch(
            typedFrontendPassApi.postReq("/api/customer/auth/passwordless/email/code", {
                reqBody: {
                    email,
                    redirectUrl: window.location.href,
                },
            }),
            () =>
                dispatch({
                    type: "REQUESTING_TOKEN_CODE",
                    payload: undefined,
                }),
            (failure) => {
                onErrorToast(failure.error.message);
                return dispatch({
                    type: "ERROR_REQUESTING_TOKEN_CODE",
                    payload: {
                        errorMessage: failure.error.message,
                    },
                });
            },
            () => {
                onSuccessToast("Verification email sent!");
                return dispatch({
                    type: "REQUESTED_TOKEN_CODE",
                    payload: {},
                });
            },
        );
    };
